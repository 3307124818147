<template>
  <div class="">暂未开放</div>
</template>

<script>
export default {
  name: 'otherSetting',
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="less"></style>
